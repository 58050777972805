
.rs-call-us{
    background: url(../img/bg/testimonial-bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $extraDarkColor;
    .image-part{
        img{
            max-height: 585px;
        }
    }
    .address-item{
        .address-icon{
            display: inline-block;
            position: relative;
            height: 70px;
            width: 70px;
            line-height: 70px;
            border-radius: 100%;
            text-align: center;
            background: $whiteColor;
            i{
                text-align: center;
                color: $primaryColor;
                border-radius: 100%;
                font-size: 25px;
                position: relative;
                line-height: 70px;
                z-index: 9;
                width: 100%;
                vertical-align: middle;
                margin-left: 0;
                height: auto;
            }
            &:before{
                content: "";
                position: absolute;
                z-index: 0;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                display: block;
                width: 70px;
                height: 70px;
                border-radius: 50%;
                animation: pulse-border 1500ms ease-out infinite;
                background: $whiteColor;
            }
        }
    }
}